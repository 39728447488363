import React, { useState } from "react";
import Icon from "../../../../components/Icon";
import cn from "classnames";
import { Service } from "../../../../models/userModels";
import PhotoSlider from "../../../../components/SpecialistInfo/PhotoSlider";
import { useDeleteSpecialistServiceMutation } from "../../../../store/user/user.api";
import { TranslationObject } from "../../../../models/translationModal";
import ConfirmDeleteModal from "../../../../components/Modals/ConfirmDeleteModal/ConfirmDeleteModal";
import {IS_CUSTOMER_ADDRESS, IS_MY_ADDRESS, IS_REMOTE} from "../../../../store/order/constant";

interface ServicesYouProvideProps {
    userService?: Service[];
    handleChangeAddService: (serviceId?: number | string) => unknown;
    entities?: TranslationObject;
}

const getSpecialistMeetingPlace = (
    remote: boolean,
    specialist: boolean,
    customer: boolean,
    entities?: TranslationObject
) => {
    const resultStr: string[] = [];
    if (remote) {
        resultStr.push(entities?.common_remotely?.value || "");
    }

    if (specialist) {
        resultStr.push(entities?.common_specialist_adress?.value || "");
    }

    if (customer) {
        resultStr.push(entities?.common_customer_adress?.value || "");
    }

    return resultStr?.join(", ");
};

export const ServicesYouProvide: React.FC<ServicesYouProvideProps> = ({
    userService,
    handleChangeAddService,
    entities,
}) => {
    const [openServiceId, setOpenServiceId] = useState<number | null>(null);

    const [deleteSpecialistService, { isLoading: isLodaingDelete }] =
        useDeleteSpecialistServiceMutation();

    const handleClickDeleteService = (id: string | number) => {
        if (isLodaingDelete) return;
        deleteSpecialistService(id);
    };

    const points = {
        [IS_REMOTE]: `${entities?.common_remotely?.value}`,
        [IS_MY_ADDRESS]: `${entities?.common_specialist_adress?.value}`,
        [IS_CUSTOMER_ADDRESS]: `${entities?.common_customer_adress?.value}`,
    };

    return (
        <>
            <div
                className={cn("specialist-profile__block", {
                    ["specialist-profile__block--flex"]: !userService?.length,
                })}
            >
                <div className="specialist-profile__col">
                    <h3 className="specialist-profile__subtitle title title--h3">
                        {entities?.specialist_details_what_services_provide
                            ?.value || ""}
                        <span className="asterisk">*</span>
                    </h3>
                    {!userService?.length && (
                        <p className="text color-grey">
                            {entities?.specialist_details_services?.value || ""}
                        </p>
                    )}
                </div>
                {userService?.map(
                    ({
                        id,
                        service_type,
                        custom_service_type,
                        service_category,
                        is_remote,
                        specialist_address,
                        customer_address,
                        description,
                        service_sub_types,
                        photos,
                    }) => (
                        <div className="profile-block grey-card" key={id}>
                            {(!is_remote &&
                                !specialist_address &&
                                !customer_address) && (
                                <div className="error">
                                <span style={{color: 'red', paddingLeft: 0}}>
                                {entities
                                    ?.address_must_be_filled
                                    ?.value}
                                </span>
                                </div>
                            )}
                            <div className="profile-block__row">
                                <div className="profile-block__col">
                                    <div
                                        className={cn("dropdown", {
                                            "is-open": openServiceId === id,
                                            "is-close": openServiceId !== id,
                                        })}
                                    >
                                        <div className="dropdown__header">
                                            <h2 className="dropdown__title title title--f32">
                                                {service_type?.name ||
                                                    custom_service_type}
                                            </h2>
                                            <button
                                                onClick={() =>
                                                    setOpenServiceId((state) =>
                                                        state === id ? null : id
                                                    )
                                                }
                                                className="dropdown__btn btn btn--transparent btn--nb desktop-only"
                                                type="button"
                                                aria-label="toggler"
                                            >
                                                <Icon
                                                    className={cn(
                                                        "transition-transform",
                                                        {
                                                            rotate180:
                                                                openServiceId ===
                                                                id,
                                                        }
                                                    )}
                                                    type="arrow_down"
                                                    width={20}
                                                    height={20}
                                                />
                                            </button>
                                        </div>
                                        <div className="dropdown__content">
                                            <p className="dropdown__item dropdown__sub subtitle subtitle--sm">
                                                {service_category?.name}
                                            </p>
                                            {(is_remote ||
                                                specialist_address ||
                                                customer_address) && (
                                                <>
                                                    <h3 className="dropdown__item title title--h4">
                                                        {
                                                            entities
                                                                ?.common_meeting_place
                                                                ?.value
                                                        }
                                                    </h3>
                                                    <div className="dropdown__item text">
                                                        {(is_remote) && (
                                                            <p>
                                                                <b>{points["is_remote"]}</b>
                                                            </p>
                                                        )}
                                                        {(specialist_address) && (
                                                            <p>
                                                                <b>{points["is_my_address"]}: {specialist_address?.value}</b>
                                                            </p>
                                                        )}
                                                        {(customer_address) && (
                                                            <p>
                                                                <b>{points["is_customer_address"]}: {customer_address?.value}, {entities?.in_distance_of?.value.replace(":radius", customer_address?.radius)}</b>
                                                            </p>
                                                        )}
                                                    </div>
                                                </>
                                            )}
                                            <p className="dropdown__item text color-grey">
                                                {description}
                                            </p>
                                            <div
                                                className="dropdown__item details"
                                                key={id}
                                            >
                                                <dl>
                                                    {service_sub_types?.map(
                                                        ({
                                                            id,
                                                            service_sub_type,
                                                            custom_service_sub_type,
                                                            price,
                                                            description,
                                                        }) => (
                                                            <React.Fragment
                                                                key={id}
                                                            >
                                                                <dt>
                                                                    {custom_service_sub_type ||
                                                                        service_sub_type?.name}
                                                                    <p className="details__text text color-grey">
                                                                        {
                                                                            description
                                                                        }
                                                                    </p>
                                                                </dt>
                                                                {price && (
                                                                    <dd>
                                                                        {price}
                                                                    </dd>
                                                                )}
                                                            </React.Fragment>
                                                        )
                                                    )}
                                                </dl>
                                            </div>
                                            {(photos?.length && (
                                                <PhotoSlider
                                                    photos={photos}
                                                    entities={entities}
                                                />
                                            )) ||
                                                null}
                                        </div>
                                    </div>
                                </div>
                                <div className="profile-block__controls profile-block__controls--centered">
                                    <button
                                        onClick={() =>
                                            handleChangeAddService(id)
                                        }
                                        className="profile-block__btn btn btn--no-bg"
                                        type="button"
                                        aria-label={entities?.order_edit?.value}
                                    >
                                        <Icon
                                            type="edit-current-color"
                                            width={20}
                                            height={20}
                                            colorVariant={"jaguar"}
                                        />
                                    </button>
                                    <ConfirmDeleteModal
                                        entities={entities}
                                        classes={["profile-block__btn", "btn", "btn--no-bg"]}
                                        handleClick={() => handleClickDeleteService(id)}
                                        ariaLabel={entities?.profile_destroy_button_ok?.value}
                                    >
                                        <Icon
                                            type="close-input-current-color"
                                            width={20}
                                            height={20}
                                            colorVariant={"grayLight"}
                                        />
                                    </ConfirmDeleteModal>
                                </div>
                            </div>
                        </div>
                    )
                ) || null}
                <button
                    className={cn("btn btn--green", {
                        ["specialist-profile__btn"]: !userService?.length,
                        ["specialist-profile__block-btn"]: userService?.length,
                    })}
                    onClick={() => handleChangeAddService()}
                    type="button"
                >
                    {entities?.specialist_details_add_service?.value || ""}
                </button>
            </div>
        </>
    );
};
