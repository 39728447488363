import React, { useEffect } from "react";
import SubMenu from "../../components/SubMenu";
import { TranslationObject } from "../../models/translationModal";
import { ProfilePayload } from "../../models/userModels";
import { ChatContainer, ChatForm, ChatUserCard, ChatWindow } from "./elements";
import { useParams } from "react-router-dom";
import { OrderInformation } from "./elements/OrderInformation";
import {
    endpoints as chatEndpoints,
    usePinChatMutation,
    useUnpinChatMutation,
} from "../../store/chat/chat.api";
import ChatsSidebar from "../../components/ChatsSidebar";
import { useDispatch, useSelector } from "react-redux";
import { util } from "../../store/orders/orders.api";
import { isActiveChatSelector } from "../../store/chat/chat.selectors";
import { useActions } from "../../hooks/actions";
import classNames from "classnames";

interface OrderChatProps {
    entities: TranslationObject;
    profile?: ProfilePayload;
}

export const OrderChat: React.FC<OrderChatProps> = ({ entities, profile }) => {
    const dispatch = useDispatch();

    const { setIsActiveChat } = useActions();

    const isActiveChat = useSelector(isActiveChatSelector);

    const { id: userId } = profile || {};

    const { chatId, role, id: orderId } = useParams();

    const [getChat, { data: chat }] = chatEndpoints.getChatInfo.useLazyQuery();

    const [pinChat, { isSuccess: isSuccessPinChat }] = usePinChatMutation();

    const [unpinChat, { isSuccess: isSuccessUnpinChat }] =
        useUnpinChatMutation();

    const {
        order,
        specialist,
        customer,
        is_pinned_by_customer: pinnedChatCustomer,
        is_pinned_by_specialist: pinnedChatSpecialist,
    } = chat || {};

    const { status } = order || {};
    const isChat = true;

    const pinnedChatCurrentRole =
        role === "customer"
            ? !!pinnedChatCustomer
            : role === "specialist"
                ? !!pinnedChatSpecialist
                : false;

    const pinOrUnpinChat = () => {
        if (!chatId || !isActiveChat) return;

        if (role !== "specialist" && role !== "customer") return;

        if (pinnedChatCurrentRole) {
            unpinChat(chatId);
        } else {
            pinChat(chatId);
        }
    };

    useEffect(() => {
        if (chatId) {
            getChat(chatId);
        }
    }, [chatId]);

    useEffect(() => {
        if (isSuccessPinChat || isSuccessUnpinChat) {
            dispatch(util.invalidateTags([{ type: "Orders", id: "CHAT" }]));
        }
    }, [isSuccessPinChat, isSuccessUnpinChat]);

    useEffect(() => {
        if (specialist && specialist?.status !== "active") {
            setIsActiveChat(false);
        } else if (customer && customer?.status !== "active") {
            setIsActiveChat(false);
        } else {
            setIsActiveChat(true);
        }
    }, [status, specialist, customer]);

    return (
        <>
            <main>
                <div
                    className={classNames("page", {
                        "specialist-chat": role === "specialist",
                        "customer-chat": role === "customer",
                    })}
                >
                    <SubMenu entities={entities} profile={profile} />
                    {chat && (
                        <>
                            {order && (
                                <OrderInformation
                                    order={order}
                                    entities={entities}
                                    profile={profile}
                                    isChatPage={isChat}
                                />
                            )}
                            <ChatContainer entities={entities}>
                                {(specialist || customer) && (
                                    <ChatUserCard
                                        specialist={specialist}
                                        customer={customer}
                                        pinnedChatStatus={pinnedChatCurrentRole}
                                        pinOrUnpinChat={pinOrUnpinChat}
                                        entities={entities}
                                        order={order}
                                    />
                                )}
                                {orderId && (
                                    <ChatsSidebar
                                        classNames={"chat__pinned"}
                                        showIfNotChats={true}
                                        id={+orderId}
                                        entities={entities}
                                        profile={profile}
                                    />
                                )}
                                <ChatWindow
                                    userId={userId}
                                    chatId={chatId}
                                    role={role}
                                    entities={entities}
                                />
                                {isActiveChat && (
                                    <ChatForm
                                        role={role}
                                        orderId={orderId}
                                        chatId={chatId}
                                        offer={order?.offer}
                                        price={order?.deal_offer_fee}
                                        entities={entities}
                                    />
                                )}
                            </ChatContainer>
                        </>
                    )}
                </div>
            </main>
        </>
    );
};
