import React, { useEffect, useState } from "react";
import Icon from "../Icon";
import _ from "lodash";
import { TranslationObject } from "../../models/translationModal";
import { ChangeStepType } from "../../pages/Registration/SpecialistNewService/SpecialistNewService";
import MyAddressModal from "../../pages/CreateOrder/Specialists/Modals/MyAddressModal";
import SpecialistAddressModal from "../../pages/CreateOrder/Specialists/Modals/SpecialistAddressModal";
import Form from "../Form/Form";
import Checkbox from "../Form/Checkbox";
import SelectedAddress from "../../pages/CreateOrder/Specialists/SelectedAddress";
import {
    IS_MY_ADDRESS,
    IS_SPECIALIST_ADDRESS,
} from "../../store/order/constant";
import { locationProps } from "../../models/orderModel";
import TextArea from "../Form/TextArea";
import { endpoints } from "../../store/directories/directories.api";
import RadioInput from "../Form/RadioInput";
import {
    IEditableType,
    SubTypeModal,
} from "../../pages/ProfileSpecialist/elements/SubTypeModal";
import { useSaveNewSpecialistService } from "../../pages/ProfileSpecialist/hooks";
import { useDeleteSpecialistServicePhotoMutation } from "../../store/user/user.api";
import WarnModal from "../Modals/WarnModal";
import { useHistoryBackHandler } from "../../hooks/useHistoryBackHandler";
import SimpleBar from "simplebar-react";
import CheckList from "../CheckList";
import classNames from "classnames";
import ConfirmDeleteModal from "../Modals/ConfirmDeleteModal/ConfirmDeleteModal";

type ServiceFilterType = "single" | "multiple";

interface IServiceFilterValue {
    valueId: number;
    valueName: string;
    active: boolean;
}

export interface IServiceFilter {
    id: number;
    name: string;
    type: ServiceFilterType;
    values: IServiceFilterValue[];
}

interface IMyAddress {
    value: string;
    latitude: number;
    longitude: number;
}

interface ICustomerAddress extends IMyAddress {
    radius: number;
}

export interface ISubType {
    id: number;
    customServiceSubType: string | null;
    price: string | number | null;
    description: string;
    subTypeElement: {
        subTypeId: number;
        subTypeName: string;
    } | null;
}

interface SpecialistServiceDescriptionNewProps {
    entities?: TranslationObject;
    changeStep: ChangeStepType;
    servicePayload: any;
}

const maxLengthDescriptionService = 1000;

export const SpecialistServiceDescriptionNew: React.FC<
    SpecialistServiceDescriptionNewProps
> = (props) => {
    const { entities, changeStep, servicePayload } = props || {};

    const [isRemote, setIsRemote] = useState<boolean>(false);

    const [myAddress, setMyAddress] = useState<IMyAddress | null>(null);

    const [showMyAddressModal, setMyAddressModal] = useState<boolean>(false);

    const [customerAddress, setCustomerAddress] =
        useState<ICustomerAddress | null>(null);

    const [showCustomerAddressModal, setCustomerAddressModal] =
        useState<boolean>(false);

    const [description, setDescription] = useState<string>("");

    const [subTypes, setSubTypes] = useState<ISubType[]>([]);

    const [showSubTypeModal, setShowSubTypeModal] = useState<boolean>(false);

    const [editSubType, setEditSubType] = useState<number | null>(null);

    const [newFiles, setNewFiles] = useState<
        Array<{ id: number; name: string; file: Blob; url: string }>
    >([]);

    const [showWarnModal, setShowWarnModal] = useState<boolean>(false);

    const [validationError, setValidationError] = useState<unknown>(null)

    const [errorRequired, setErrorRequired] = useState<boolean>(false)

    const [ids, setIds] = useState<any[]>([]);

    const { saveService, prepareDataService, saveIsSuccess, error } =
        useSaveNewSpecialistService();

    const typeName =
        servicePayload?.service_type?.name ||
        servicePayload?.custom_service_type ||
        "";

    const serviceId = servicePayload?.id;

    const categoryName = servicePayload?.service_category?.name || "";

    const typeId = servicePayload?.service_type?.id;

    const subTypesPayload = servicePayload?.service_sub_types || [];

    const filtersPayload = servicePayload?.filters || [];

    const oldPhotos = servicePayload?.photos || [];

    const { data: filters } = endpoints.getFilters.useQuery(
        {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            id: typeId,
        },
        { skip: typeof typeId !== "number" || Number.isNaN(typeId) }
    );

    const titlePlaceConvenient = entities
        ?.specialist_details_place_convenient
        ?.value
    const titleDescription = entities
        ?.specialist_details_description
        ?.value

    const [deleteSpecialistServicePhoto] =
        useDeleteSpecialistServicePhotoMutation();

    const [serviceFilters, setServiceFilters] = useState<IServiceFilter[]>([]);

    useHistoryBackHandler(() => changeStep("subType"));

    useEffect(() => {
        if (saveIsSuccess) {
            setShowWarnModal(true);
        }
    }, [saveIsSuccess]);

    useEffect(() => {
        if (servicePayload) {
            setNewFiles([]);

            const description = servicePayload?.description || "";

            const isRemote = servicePayload?.is_remote;

            const specialistAddress =
                servicePayload?.specialist_address || null;

            const customerAddress = servicePayload?.customer_address || null;

            if (
                customerAddress?.value &&
                customerAddress?.latitude &&
                customerAddress?.longitude &&
                customerAddress?.radius !== undefined
            ) {
                setCustomerAddress(customerAddress);
            }

            if (
                specialistAddress?.value &&
                specialistAddress?.latitude &&
                specialistAddress?.longitude
            ) {
                setMyAddress(specialistAddress);
            }

            if (isRemote) {
                setIsRemote(isRemote);
            }

            if (description) {
                setDescription(description);
            }
        }
    }, [servicePayload]);

    useEffect(() => {
        const activeFiltersPayload = filtersPayload?.map((filter) => {
            return {
                filterId: filter?.service_type_filter?.id,
                filterActiveValues: filter?.filter_values?.map((el) => el?.id),
            };
        });

        if (filters?.length) {
            const filtersArr = filters?.map((filter) => {
                const formatFilter: IServiceFilter = {
                    id: filter?.id,
                    name: filter?.name,
                    type: filter?.type as ServiceFilterType,
                    values: filter?.values?.map((value) => {
                        const activeValue = activeFiltersPayload?.find(
                            (el) => {
                                if (
                                    el?.filterId === filter?.id &&
                                    el?.filterActiveValues?.includes(
                                        value?.id
                                    )
                                ) {
                                    return el;
                                }
                            }
                        );
                        return {
                            valueId: value?.id,
                            valueName: value?.name,
                            active: !!activeValue,
                        };
                    }),
                };
                return formatFilter;
            });

            setServiceFilters(filtersArr);
            setIds(getSelectedFiltersIds(filtersArr));
        }
    }, [filters, filtersPayload]);

    useEffect(() => {
        if (subTypesPayload?.length) {
            setSubTypes(
                subTypesPayload?.map((subType) => {
                    return {
                        id: subType?.id,
                        customServiceSubType: subType?.custom_service_sub_type,
                        price: subType?.price,
                        description: subType?.description,
                        subTypeElement: !subType?.custom_service_sub_type
                            ? {
                                subTypeId: subType?.service_sub_type?.id,
                                subTypeName: subType?.service_sub_type?.name,
                            }
                            : null,
                    };
                })
            );
        }
    }, [subTypesPayload]);

    const addFile = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const elems: FileList | null = e.target.files;
        if (elems) {
            const keys = Object.keys(elems);

            const items: any[] = [];
            keys.forEach((elem: any) => {
                if (elems[elem]) {
                    const id = +_.uniqueId();
                    const file = elems[elem];
                    const name = file.name;

                    const url = window.URL.createObjectURL(elems[elem]);
                    items.push({ id, name, file, url });
                }
            });
            setNewFiles((oldFiles) => [...oldFiles, ...items]);
        }
    };

    const deleteNewFile =
        (fileId: number): (() => void) =>
            () => {
                setNewFiles((files) => {
                    return files.filter((file) => file?.id !== fileId);
                });
            };

    const saveMyAddressModal = (location: locationProps) => {
        const { latitude, longitude, address } = location || {};
        if (latitude && longitude && address) {
            setMyAddress({
                value: address,
                latitude,
                longitude,
            });
        }
        setMyAddressModal(false);
    };

    const saveCustomerAddressModal = (location: locationProps) => {
        setErrorRequired(false)
        const { latitude, longitude, address: value, radius } = location || {};
        if (!radius) {
            return setErrorRequired(true)
        }

        if (latitude && longitude && value && radius) {
            setCustomerAddress({
                value,
                latitude,
                longitude,
                radius: +radius,
            });
        }
        setCustomerAddressModal(false);
    };

    const handleChangeMyAddressCheckbox = () => {
        if (myAddress) {
            setMyAddress(null);
        } else {
            setMyAddressModal(true);
        }
    };

    const handleChangeCustomerAddressCheckbox = () => {
        if (customerAddress) {
            setCustomerAddress(null);
        } else {
            setCustomerAddressModal(true);
        }
    };

    const handleClickEditSubType = (id: number) => {
        setEditSubType(id);
        setShowSubTypeModal(true);
    };

    const getSelectedFiltersIds = (array) => {
        return array.map((filter) => {
            return {
                filterId: filter.id,
                ids: filter.values.reduce((acc: number[], elm) => {
                    if (elm.active) {
                        return [...acc, elm.valueId];
                    }
                    return acc;
                }, [])
            }
        })
    }

    const subTypeModalTitle = subTypes?.reduce((acc, curr) => {
        if (editSubType === curr?.id) {
            acc =
                curr?.subTypeElement?.subTypeName ||
                curr?.customServiceSubType ||
                entities?.specialist_details_new_service?.value ||
                "";
        }
        return acc;
    }, entities?.specialist_details_new_service?.value || "");

    const subTypeModalEditableType = subTypes?.reduce(
        (acc: IEditableType | undefined, curr) => {
            if (editSubType === curr?.id) {
                acc = {
                    id: curr?.subTypeElement?.subTypeId ?? null,
                    customType: curr?.customServiceSubType,
                    description: curr?.description,
                    price: curr?.price,
                };
            }
            return acc;
        },
        undefined
    );

    const saveSubTypeModal = (data) => {
        if (subTypes?.find((el) => el?.id === editSubType)) {
            setSubTypes((state) => {
                return state?.map((subType) => {
                    if (editSubType === subType?.id) {
                        return {
                            id: subType?.id,
                            customServiceSubType: data?.customSubType || null,
                            price: data?.price || null,
                            description: data?.description || null,
                            subTypeElement: data?.customSubType
                                ? null
                                : {
                                    subTypeId: data?.id,
                                    subTypeName: data?.name,
                                },
                        };
                    }
                    return subType;
                });
            });
        } else {
            const newSubType: ISubType = {
                id: +_.uniqueId(),
                price: data?.price || null,
                customServiceSubType: data?.customSubType || null,
                description: data?.description || null,
                subTypeElement:
                    typeof data?.id === "number" &&
                        typeof data?.name === "string"
                        ? {
                            subTypeId: data?.id,
                            subTypeName: data?.name,
                        }
                        : null,
            };
            setSubTypes((state) => {
                return [...state, newSubType];
            });
        }
        setEditSubType(null);
    };

    const deleteSubType = (id: number) => {
        setSubTypes((state) => {
            return state?.filter((subType) => subType?.id !== id);
        });
    };

    const handleChangeFilterInput =
        (
            type: "multiple" | "single",
            filterId: number,
            filterInputId: number
        ) => {
                let newFiltersValue: IServiceFilter[] = [];
                if (type === "multiple") {
                    newFiltersValue = serviceFilters.map((filter) => {
                        if (filter.id === filterId) {
                            filter.values = filter.values.map((input) => {
                                if (input.valueId === filterInputId) {
                                    input.active = input.active !== true;
                                }
                                return input;
                            });
                        }
                        return filter;
                    });

                    setIds(getSelectedFiltersIds(serviceFilters));
                } else if (type === "single") {
                    newFiltersValue = serviceFilters.map((filter) => {
                        if (filter.id === filterId) {
                            filter.values = filter.values.map((input) => {
                                input.active = input.valueId === filterInputId;
                                return input;
                            });
                        }
                        return filter;
                    });
                } else {
                    return;
                }
                setServiceFilters(newFiltersValue);
            };

    const prepareDataServiceAndSave = () => {
        setValidationError(null)
        const { data, isValidData } = prepareDataService({
            is_remote: isRemote,
            customer_address: customerAddress,
            specialist_address: myAddress,
            description: description,
            specialist_service_sub_types: subTypes,
            specialist_service_type_filters: serviceFilters,
            photos: newFiles,
        });

        if (typeof isValidData === 'string') {
            return setValidationError({
                status: 422,
                data: {
                    description: "validation.required",
                    payload: {
                        errors: { [isValidData]: { Required: [] } },
                        messages: { [isValidData]: ['validation.required'] }
                    },
                }
            })
        }

        if (serviceId) {
            saveService(serviceId, data);
        }
    };

    const isConfirmService = (() => {

        if (!isRemote && !customerAddress && !myAddress) {
            return false;
        }

        return true;
    })();

    const deleteOldPhoto = (photoId: number) => {
        if (serviceId && photoId) {
            deleteSpecialistServicePhoto({
                serviceId,
                photoId,
            });
        }
    };

    const getSelectedFilterId = (values: IServiceFilterValue[]) => {
        return values.find(elem => elem.active)?.valueId ?? null;
    }

    const handleClickDeleteSubtype = (subTypeId: number): (() => void) =>
    () => {
        if (!subTypeId) return;

        deleteSubType(subTypeId);
    };

    return (
        <>
            <WarnModal
                key={"WarnModal"}
                title={entities?.common_saved?.value || ""}
                onClose={() => changeStep("category", true)}
                visible={showWarnModal}
                description={
                    entities?.common_information_has_been_saved?.value || ""
                }
                isSpecialist={true}
            />
            <MyAddressModal
                key="MyAddressModal"
                save={saveMyAddressModal}
                close={() => setMyAddressModal(false)}
                entities={entities}
                showMyAddressModal={showMyAddressModal}
                isSpecialist={true}
            />

            <SpecialistAddressModal
                key="SpecialistAddressModal"
                save={saveCustomerAddressModal}
                close={() => setCustomerAddressModal(false)}
                entities={entities}
                showSpecialistAddressModal={showCustomerAddressModal}
                isSpecialist={true}
                errorRequired={errorRequired}
                showUserAddress={true}
            />

            <SubTypeModal
                key={"SubTypeModal"}
                showSubTypeModal={showSubTypeModal}
                closeSubTypeModal={() => setShowSubTypeModal(false)}
                activeType={
                    servicePayload?.custom_service_type ||
                    servicePayload?.service_type?.id
                }
                saveSubTypeModal={saveSubTypeModal}
                subTypeTitle={subTypeModalTitle}
                editableType={subTypeModalEditableType}
                entities={entities}
            />
            <section className="spec-page order-details">
                <div className="spec-page__container container">
                    <h1 className="order-details__title title title--f46">
                        {typeName}
                    </h1>
                    <p className="subtitle">{categoryName}</p>
                    <div className="form">
                        <Form
                            entities={entities}
                            onSubmit={() => null}
                            isSuccess={false}
                            error={validationError || error}
                        >
                            {(invalidFields, error) => (
                                <>
                                    <div className="form__block">
                                        <h2 className="form__title title title--f32">
                                            {titlePlaceConvenient}
                                            {titlePlaceConvenient && (
                                                <span className="asterisk">
                                                    *
                                                </span>
                                            )}
                                        </h2>
                                        <div className="toggle-place">
                                            <ul className="toggle-place__list">
                                                <li className="toggle-place__item">
                                                    <Checkbox
                                                        classes={
                                                            "custom-toggle--green"
                                                        }
                                                        value={isRemote}
                                                        onChange={() =>
                                                            setIsRemote(
                                                                (state) =>
                                                                    !state
                                                            )
                                                        }
                                                        error={error}
                                                        invalidFields={
                                                            invalidFields
                                                        }
                                                    >
                                                        {
                                                            entities
                                                                ?.common_remotely
                                                                ?.value
                                                        }
                                                    </Checkbox>
                                                </li>
                                                <li className="toggle-place__item">
                                                    <Checkbox
                                                        classes={
                                                            "custom-toggle--green"
                                                        }
                                                        value={!!myAddress}
                                                        onChange={
                                                            handleChangeMyAddressCheckbox
                                                        }
                                                        name="specialist_address"
                                                        error={error}
                                                        invalidFields={
                                                            invalidFields
                                                        }
                                                    >
                                                        {
                                                            entities
                                                                ?.common_my_address
                                                                ?.value
                                                        }
                                                    </Checkbox>
                                                    <SelectedAddress
                                                        showAddress={
                                                            !!myAddress
                                                        }
                                                        clearAddress={() =>
                                                            setMyAddress(null)
                                                        }
                                                        type={IS_MY_ADDRESS}
                                                        location={{
                                                            longitude:
                                                                myAddress?.longitude ??
                                                                0,
                                                            latitude:
                                                                myAddress?.latitude ??
                                                                0,
                                                            address:
                                                                myAddress?.value ??
                                                                "",
                                                        }}
                                                    />
                                                </li>
                                                <li className="toggle-place__item">
                                                    <Checkbox
                                                        classes={
                                                            "custom-toggle--green"
                                                        }
                                                        name="customer_address"
                                                        value={
                                                            !!customerAddress
                                                        }
                                                        onChange={
                                                            handleChangeCustomerAddressCheckbox
                                                        }
                                                        error={error}
                                                        invalidFields={
                                                            invalidFields
                                                        }
                                                    >
                                                        {
                                                            entities
                                                                ?.common_customer_adress
                                                                ?.value
                                                        }
                                                    </Checkbox>
                                                    <SelectedAddress
                                                        showAddress={
                                                            !!customerAddress
                                                        }
                                                        clearAddress={() =>
                                                            setCustomerAddress(
                                                                null
                                                            )
                                                        }
                                                        type={
                                                            IS_SPECIALIST_ADDRESS
                                                        }
                                                        location={{
                                                            longitude:
                                                                customerAddress?.longitude ??
                                                                0,
                                                            latitude:
                                                                customerAddress?.latitude ??
                                                                0,
                                                            address:
                                                                customerAddress?.value ??
                                                                "",
                                                            radius:
                                                                customerAddress?.radius ??
                                                                0,
                                                        }}
                                                    />
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="form__block">
                                        <h2 className="form__title title title--f32">
                                            {titleDescription}
                                        </h2>
                                        <TextArea
                                            classes={[
                                                "custom-textarea form__field",
                                            ]}
                                            type={"text"}
                                            invalidFields={invalidFields}
                                            name="description"
                                            error={error}
                                            placeholder={
                                                entities
                                                    ?.specialist_details_describe_your_experience
                                                    ?.value
                                            }
                                            defaultValue={description}
                                            onChange={setDescription}
                                            maxLength={
                                                maxLengthDescriptionService
                                            }
                                        />
                                    </div>
                                    {serviceFilters?.map(
                                        ({ id, name, type, values }, index) => (
                                            <div
                                                className="form__block form__block--specialist--description"
                                                key={id}
                                            >
                                                <h2 className="form__title title title--f32">
                                                    {name}
                                                    {name && (
                                                        <span className="asterisk">
                                                            *
                                                        </span>
                                                    )}
                                                </h2>
                                                {type === "single" && <>
                                                        {values.length > 10 &&
                                                            <div className="custom-toggle--radio-list-selected">
                                                                {
                                                                    values.filter(el => el.active).map((elem) => <>
                                                                        <RadioInput
                                                                            key={elem.valueId}
                                                                            checked={true}
                                                                            label={elem?.valueName}
                                                                        />
                                                                    </>)
                                                                }
                                                            </div>
                                                        }

                                                        <CheckList
                                                            entities={entities}
                                                            isSuccess={true}
                                                            inputName={`specialist_service_type_filters.${index + 1}.service_type_filter_value_ids`}
                                                            onChange={(valueId: number) => handleChangeFilterInput(
                                                                type,
                                                                id,
                                                                valueId
                                                            )}
                                                            items={values.map((elem) => ({
                                                                name: elem.valueName,
                                                                id: elem.valueId,
                                                            })) || []}
                                                            id={getSelectedFilterId(values)}
                                                            error={error}
                                                            invalidFields={invalidFields}
                                                            classes={"custom-toggle--green custom-toggle--scrollbar"}
                                                        />
                                                    </>
                                                }
                                                {type === "multiple" &&
                                                    <div  className={classNames("custom-toggle--checkbox-list", {
                                                        "is-invalid":
                                                            !!invalidFields &&
                                                            invalidFields?.has(`specialist_service_type_filters.${index + 1}.service_type_filter_value_ids`),
                                                    })}>
                                                        {values.length > 10 &&
                                                            <div className="custom-toggle--checkbox-list-selected">
                                                                {
                                                                    values.filter(el => el.active).map((elem) => <>
                                                                        <Checkbox
                                                                            key={elem.valueId}
                                                                            value={true}
                                                                            onChange={() => handleChangeFilterInput(
                                                                                type,
                                                                                id,
                                                                                elem.valueId
                                                                            )}
                                                                        >
                                                                            {elem?.valueName}
                                                                        </Checkbox>
                                                                    </>)
                                                                }
                                                            </div>
                                                        }

                                                        <CheckList
                                                            entities={entities}
                                                            isMultiple={true}
                                                            isSuccess={true}
                                                            inputName={`specialist_service_type_filters.${index + 1}.service_type_filter_value_ids`}
                                                            onChange={(valueId: number) => handleChangeFilterInput(
                                                                type,
                                                                id,
                                                                valueId
                                                            )}
                                                            ids={ids.find(elm => elm.filterId === id)?.ids || []}
                                                            items={values.map((elem) => ({
                                                                name: elem.valueName,
                                                                id: elem.valueId,
                                                            })) || []}
                                                            error={error}
                                                            showError={false}
                                                            invalidFields={invalidFields}
                                                            classes={"custom-toggle--green custom-toggle--scrollbar"}
                                                        />
                                                        {!!invalidFields 
                                                            && invalidFields.has(`specialist_service_type_filters.${index + 1}.service_type_filter_value_ids`) 
                                                            && (
                                                                <div className="custom-toggle__error custom-toggle__error--checklist">
                                                                    {error?.errors[`specialist_service_type_filters.${index + 1}.service_type_filter_value_ids`]?.[0]}
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        )
                                    )}
                                    <div className="form__block">
                                        <h2 className="form__title title title--f32">
                                            {
                                                entities
                                                    ?.specialist_details_services_title
                                                    ?.value
                                            }
                                        </h2>
                                        {(subTypes?.length && (
                                            <div className="profile-block">
                                                {subTypes?.map((subType) => (
                                                    <div
                                                        className="profile-block__row"
                                                        key={subType?.id}
                                                    >
                                                        <div className="profile-block__col">
                                                            <h3 className="title title--h4">
                                                                {subType?.customServiceSubType ||
                                                                    subType
                                                                        ?.subTypeElement
                                                                        ?.subTypeName}
                                                            </h3>
                                                            <p className="text">
                                                                {subType?.description ||
                                                                    ""}
                                                            </p>
                                                        </div>
                                                        <div className="profile-block__col">
                                                            <p className="title title--h4">
                                                                {subType?.price ||
                                                                    ""}
                                                            </p>
                                                        </div>
                                                        <div className="profile-block__controls">
                                                            <button
                                                                onClick={() =>
                                                                    handleClickEditSubType(
                                                                        subType?.id
                                                                    )
                                                                }
                                                                className="profile-block__btn btn btn--no-bg"
                                                                type="button"
                                                                aria-label={
                                                                    entities
                                                                        ?.order_edit
                                                                        ?.value
                                                                }
                                                            >
                                                                <Icon
                                                                    type="edit-current-color"
                                                                    width={20}
                                                                    height={20}
                                                                    colorVariant={
                                                                        "jaguar"
                                                                    }
                                                                />
                                                            </button>
                                                            <ConfirmDeleteModal
                                                                entities={entities}
                                                                classes={["profile-block__btn", "btn", "btn--no-bg"]}
                                                                handleClick={handleClickDeleteSubtype(
                                                                    subType?.id
                                                                )}
                                                                ariaLabel={entities?.profile_destroy_button_ok?.value}
                                                            >
                                                                <Icon
                                                                    type="close-input-current-color"
                                                                    width={20}
                                                                    height={20}
                                                                    colorVariant={"grayLight"}
                                                                />
                                                            </ConfirmDeleteModal>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        )) ||
                                            null}
                                        <button
                                            onClick={() =>
                                                setShowSubTypeModal(true)
                                            }
                                            className="btn btn--centered btn--transparent"
                                            type="button"
                                        >
                                            {
                                                entities
                                                    ?.specialist_details_add_services
                                                    ?.value
                                            }
                                        </button>
                                    </div>
                                    <div className="form__block">
                                        <h2 className="form__title title title--f32">
                                            {
                                                entities
                                                    ?.specialist_details_service_photo
                                                    ?.value
                                            }
                                        </h2>
                                        <ul className="photo-stream">
                                            {oldPhotos?.map(
                                                ({
                                                    id,
                                                    original_url,
                                                    file_name,
                                                }: {
                                                    id: number;
                                                    original_url: string;
                                                    file_name: string;
                                                }) => (
                                                    <div
                                                        className="upload not-empty"
                                                        key={id}
                                                    >
                                                        <div className="upload__content">
                                                            <img
                                                                src={
                                                                    original_url
                                                                }
                                                                width="191"
                                                                height="190"
                                                                alt={file_name}
                                                            />
                                                            <button
                                                                className="upload__delete-btn btn btn--no-bg"
                                                                type="button"
                                                                onClick={() =>
                                                                    deleteOldPhoto(
                                                                        id
                                                                    )
                                                                }
                                                            >
                                                                <Icon
                                                                    type={
                                                                        "delete-current-color"
                                                                    }
                                                                    colorVariant={
                                                                        "light"
                                                                    }
                                                                    height={16}
                                                                    width={16}
                                                                />
                                                            </button>
                                                        </div>
                                                    </div>
                                                )
                                            )}
                                            {newFiles?.map(({ id, url }) => (
                                                <div
                                                    className="upload not-empty"
                                                    key={id}
                                                >
                                                    <div className="upload__content">
                                                        <img
                                                            src={url}
                                                            width="191"
                                                            height="190"
                                                            alt="new photo"
                                                        />
                                                    </div>
                                                    <button
                                                        onClick={deleteNewFile(
                                                            id
                                                        )}
                                                        className="upload__delete-btn btn btn--no-bg"
                                                        type="button"
                                                    >
                                                        <Icon
                                                            type={
                                                                "delete-current-color"
                                                            }
                                                            colorVariant={
                                                                "light"
                                                            }
                                                            height={16}
                                                            width={16}
                                                        />
                                                    </button>
                                                </div>
                                            ))}
                                            {oldPhotos.length + newFiles.length < 10 && (
                                            <li className="upload">
                                                <label className="upload__field">
                                                    <Icon
                                                        type={"upload"}
                                                        width={40}
                                                        height={40}
                                                    />
                                                    <input
                                                        type="file"
                                                        name="file"
                                                        id="file"
                                                        onChange={addFile}
                                                        accept="image/png, image/jpeg"
                                                    />
                                                </label>
                                            </li>
                                            )}
                                        </ul>
                                    </div>
                                    <div className="form__row form__controls">
                                        <button
                                            onClick={() =>
                                                changeStep("category", true)
                                            }
                                            className="btn form__back-link btn--transparent"
                                            type="button"
                                        >
                                            {
                                                entities?.common_back_button
                                                    ?.value
                                            }
                                        </button>
                                        <button
                                            onClick={prepareDataServiceAndSave}
                                            className="btn btn btn--bg-green btn--rounded"
                                            type="button"
                                            disabled={!isConfirmService}
                                        >
                                            {
                                                entities?.common_save_button
                                                    ?.value
                                            }
                                        </button>
                                    </div>
                                </>
                            )}
                        </Form>
                    </div>
                </div>
            </section>
        </>
    );
};
