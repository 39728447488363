import React, { useEffect} from "react";
import { useNavigate } from "react-router-dom";
import Home from "./pages/Home/index";
import About from "./pages/About/index";
import TextPage from "./pages/TextPage";
import Bonus from "./pages/Bonus";
import Auth from "./pages/Auth";
import Registration from "./pages/Registration";

import Profile from "./pages/Profile";
import Advertisers from "./pages/Advertisers";
import Service from "./pages/Service";
import ContactAs from "./pages/ContactAs";
import CreateOrder from "./pages/CreateOrder";
import NotFoundPage from "./pages/NotFound";
import Price from "./pages/Price";
import { TranslationObject } from "./models/translationModal";
import SpecialistInfo from "./components/SpecialistInfo";
import ServiceCatalog from "./pages/ServiceCatalog";
import Reviews from "./pages/Reviews";
import Order from "./pages/Order";
import Orders from "./pages/Orders";
import { ProfilePayload } from "./models/userModels";
import ProfileSpecialist from "./pages/ProfileSpecialist";
import EditOrder from "./pages/EditOrder";
import UserNotifications from "./pages/UserNotifications";
import { OrderChat } from "./pages/OrderChat/OrderChat";
import { MyFinances } from "./pages/MyFinances/MyFinances";
import { Advertisement } from "./pages/Advertisement/Advertisement";
import { DirectoryPayload } from "./models/directoryModel";
import Support from "./pages/Support";

export const routes = ({
    entities,
    currentLanguage,
    isCustomerOnUrl,
    isSpecialistOnUrl,
    profile,
    data
}: {
    entities: TranslationObject;
    currentLanguage: string;
    isCustomerOnUrl: boolean;
    isSpecialistOnUrl: boolean;
    profile?: ProfilePayload;
    data?: DirectoryPayload
    }) => {
    const navigate = useNavigate();

    useEffect(() => {
        const supportedLanguages = ['en', 'ru', 'el'];
        const pathParts = location.pathname.split('/').filter(Boolean);

        if (!supportedLanguages.includes(pathParts[0])) {
            const defaultLanguage = currentLanguage ?? 'el';
            const newPath = `/${defaultLanguage}${location.pathname}`;
            navigate(newPath + location.search, { replace: true });
        }
    }, [location, navigate]);

    const items = [
        {
            path: `:currentLanguage/:role/auth`,
            element: (
                <Auth
                    entities={entities}
                    currentLanguage={currentLanguage}
                    isCustomerOnUrl={isCustomerOnUrl}
                    isSpecialistOnUrl={isSpecialistOnUrl}
                    profile={profile}
                />
            ),
            needAuth: false,
        },
        {
            path: `:currentLanguage/:role/registration`,
            element: (
                <Registration
                    profile={profile}
                    entities={entities}
                    currentLanguage={currentLanguage}
                    isCustomerOnUrl={isCustomerOnUrl}
                    isSpecialistOnUrl={isSpecialistOnUrl}
                />
            ),
            needAuth: false,
        },
        {
            path: `:currentLanguage/:role/lk/profile`,
            element: (
                <Profile
                    entities={entities}
                    currentLanguage={currentLanguage}
                    isCustomerOnUrl={isCustomerOnUrl}
                    isSpecialistOnUrl={isSpecialistOnUrl}
                    profile={profile}
                />
            ),
            needAuth: true,
        },
        {
            path: `:currentLanguage/specialist/lk/profile/*`,
            element: (
                <ProfileSpecialist
                    currentLanguage={currentLanguage}
                    entities={entities}
                    isCustomerOnUrl={isCustomerOnUrl}
                    isSpecialistOnUrl={isSpecialistOnUrl}
                    profile={profile}
                />
            ),
            needAuth: true,
        },
        {
            path: `:currentLanguage/:role/lk/reviews/:id`,
            element: (
                <Reviews
                    currentLanguage={currentLanguage}
                    entities={entities}
                    profile={profile}
                    isMyReviews
                />
            ),
            needAuth: true,
        },
        {
            path: `:currentLanguage/customer/create-order/*`,
            element: (
                <CreateOrder
                    entities={entities}
                    profile={profile}
                    currentLanguage={currentLanguage}
                />
            ),
            needAuth: false,
        },
        {
            path: `:currentLanguage/customer/create-order/category`,
            element: (
                <CreateOrder
                    entities={entities}
                    profile={profile}
                    currentLanguage={currentLanguage}
                />
            ),
            needAuth: false,
        },
        {
            path: `:currentLanguage/:role/:id`,
            element: (
                <SpecialistInfo
                    profile={profile}
                    entities={entities}
                />
            ),
            needAuth: false,
        },
        {
            path: `:currentLanguage/services`,
            element: <ServiceCatalog entities={entities} profile={profile} currentLanguage={currentLanguage} />,
            needAuth: false,
        },
        {
            path: `:currentLanguage/:role/reviews/:id`,
            element: (
                <Reviews
                    currentLanguage={currentLanguage}
                    entities={entities}
                    profile={profile}
                    isSpecialistOnUrl={isSpecialistOnUrl}
                />
            ),
            needAuth: false,
        },
        {
            path: `:currentLanguage/:role/lk/order/:id`,
            element: <Order entities={entities} profile={profile} />,
            needAuth: true,
        },
        {
            path: `:currentLanguage/:role/lk/order/:id/chat/:chatId`,
            element: <OrderChat entities={entities} profile={profile} />,
            needAuth: true,
        },
        {
            path: `:currentLanguage/:role/lk/orders`,
            element: <Orders entities={entities} profile={profile} currentLanguage={currentLanguage} />,
            needAuth: true,
        },
        {
            path: `:currentLanguage/:role/edit-order/*`,
            element: <EditOrder entities={entities} profile={profile} />,
            needAuth: true,
        },
        {
            path: `:currentLanguage/:role/lk/notifications`,
            element: (
                <UserNotifications
                    entities={entities}
                    currentLanguage={currentLanguage}
                    isSpecialistOnUrl={isSpecialistOnUrl}
                    profile={profile}
                />
            ),
            needAuth: true,
        },
        {
            path: `:currentLanguage/specialist/lk/finances`,
            element: (
                <MyFinances
                    entities={entities}
                    currentLanguage={currentLanguage}
                    profile={profile}
                />
            ),
            needAuth: true,
        },
        {
            path: `:currentLanguage/specialist/lk/advertisement`,
            element: (
                <Advertisement
                    entities={entities}
                    currentLanguage={currentLanguage}
                    profile={profile}
                />
            ),
            needAuth: true,
        },
        {
            path: `:currentLanguage/support`,
            element: (
                <Support entities={entities} profile={profile} />
            ),
            needAuth: false,
        },
        {
            path: `:currentLanguage/:role/not-found`,
            element: (
                <NotFoundPage />
            ),
            needAuth: false,
        },
        {
            path: `:currentLanguage/not-found`,
            element: (
                <NotFoundPage />
            ),
            needAuth: false,
        },
    ];

    const getComponent = (code: string) => {
        switch (code) {
            case "about":
                return (
                    <About
                        currentLanguage={currentLanguage}
                        entities={entities}
                        profile={profile}
                        isSpecialistOnUrl={isSpecialistOnUrl}
                    />
                );
            case "advertisers":
                return (
                    <Advertisers
                        profile={profile}
                        entities={entities}
                        currentLanguage={currentLanguage}
                    />
                );
            case "bonuses_and_partners":
                return (
                    <Bonus
                        isSpecialistOnUrl={isSpecialistOnUrl}
                        entities={entities}
                        profile={profile}
                        currentLanguage={currentLanguage}
                    />
                );
            case "main_page":
            case "customer_main_page":
            case "specialist_main_page":
                return (
                    <Home
                        profile={profile}
                        entities={entities}
                        currentLanguage={currentLanguage}
                    />
                );
            case "specialist_service_page":
            case "customer_service_page":
                return (
                    <Service
                        currentLanguage={currentLanguage}
                        isCustomerOnUrl={isCustomerOnUrl}
                        entities={entities}
                        profile={profile}
                    />
                );
            case "write_us":
                return (
                    <ContactAs
                        entities={entities}
                        currentLanguage={currentLanguage}
                        profile={profile}
                    />
                );
            case "specialist_prices":
                return (
                    <Price
                        currentLanguage={currentLanguage}
                        entities={entities}
                        profile={profile}
                    />
                );
            default:
                return <NotFoundPage />;
        }
    };

    data?.custom_pages?.forEach((elem) => {
        items.push({
            path: elem.path === '/' ? `:currentLanguage` : `:currentLanguage/${elem.path}`,
            element: getComponent(elem?.code),
            needAuth: false,
        });
        items.push({
            path: elem.path === '/' ? `:currentLanguage/:role` : `:currentLanguage/:role/${elem.path}`,
            element: getComponent(elem?.code),
            needAuth: false,
        });

        if (elem.path === '/') {
            items.push({
                path: '',
                element: getComponent(elem?.code),
                needAuth: false,
            });
        }
    });

    data?.text_pages?.forEach((elem) => {
        items.push({
            path: `:currentLanguage/${elem.path}`,
            element: (
                <TextPage code={elem?.code} currentLanguage={currentLanguage} />
            ),
            needAuth: false,
        });
        items.push({
            path: `:currentLanguage/:role/${elem.path}`,
            element: (
                <TextPage code={elem?.code} currentLanguage={currentLanguage} />
            ),
            needAuth: false,
        });
    });

    items.push({
        path: "*",
        element: <NotFoundPage />,
        needAuth: false,
    });

    return items;
};
