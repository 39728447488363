import React, { useEffect } from "react";
import Form from "../../components/Form/Form";
import Input from "../../components/Form/Input";
import { getTkn } from "../../store/user/selectors";
import { useActions } from "../../hooks/actions";
import { useNavigate } from "react-router-dom";
import { OrderStepsProps } from "./index";
import { ProfilePayload } from "../../models/userModels";
import {
    SERVICE_CATEGORY_ID,
    STATUS,
    TITLE,
    SERVICE_CATEGORY_NAME,
    SERVICE_TYPE_NAME,
    SERVICE_SUB_TYPE_IDS,
    SERVICE_SUB_TYPE_NAME,
    SERVICE_TYPE_SLUG,
    SERVICE_SUB_TYPE_SLUG,
    SERVICE_CATEGORY_SLUG,
} from "../../store/order/constant";
import { endpoints } from "../../store/directories/directories.api";
import { createOrderState } from "../../store/order/createOrder.slice";
import {
    getItemCreateOrder,
    updateItemCreateOrder,
} from "../../store/order/selectors";
import Advertising from "../../components/Advertising";
import CancelOrderButton from "./Specialists/CancelOrderButton";
import Breadcrumbs from "../../components/Breadcrumbs";

interface FinishingProps extends OrderStepsProps {
    currentLanguage: string;
    profile?: ProfilePayload;
    isLoading?: boolean;
}

const Finishing: React.FC<FinishingProps> = ({
    entities,
    cancelOrder,
    isSuccess,
    onSubmit,
    error,
    currentLanguage,
    profile,
    isLoading = false,
    back,
}) => {
    const token = getTkn();
    const navigate = useNavigate();
    const { updateUserField, updateCreateOrderField, clearCreateOrderFields } =
        useActions();
    const title = getItemCreateOrder(TITLE);
    const categoryId = getItemCreateOrder(SERVICE_CATEGORY_ID);
    const { data: categories } = endpoints.getServiceCategories.useQuery();
    const isLogin = profile?.id && token;
    const serviceSubTypesIdsLength = getItemCreateOrder(SERVICE_SUB_TYPE_IDS)?.length;

    const categoryPath = getItemCreateOrder(SERVICE_CATEGORY_SLUG);
    const typePath = getItemCreateOrder(SERVICE_TYPE_SLUG);
    const subtypePath = getItemCreateOrder(SERVICE_SUB_TYPE_SLUG);

    const updateField = <K extends keyof createOrderState>({
        name,
        value,
    }: {
        name: K;
        value: createOrderState[K];
    }) =>
        updateItemCreateOrder(
            {
                name: name,
                value: value,
            },
            updateCreateOrderField
        );

    // useEffect(() => {
    //     if (categoryId && !title) {
    //         const category = categories?.find(
    //             (elem) => Number(elem.id) === Number(categoryId)
    //         );
    //         if (categoryId && category) {
    //             updateField({
    //                 name: TITLE,
    //                 value: category?.name,
    //             });
    //         }
    //     }
    // }, [categories]);

    const login = () => {
        updateUserField({
            name: "urlAfterAuthorization",
            value: `/${currentLanguage}/customer/create-order`,
        });
        navigate(`/${currentLanguage}/customer/auth`);
    };

    const _onSubmit = (
        e?: React.FormEvent<HTMLInputElement>,
        withPublish?: boolean
    ) => {
        if (e) {
            e.preventDefault();
        }
        if (withPublish) {
            updateUserField({
                name: "urlAfterAuthorization",
                value: `/${currentLanguage}/customer/create-order`,
            });

            if (isLogin) {
                updateField({
                    name: STATUS,
                    value: "active",
                });
            } else {
                navigate(`/${currentLanguage}/customer/registration`);
            }
        }
        onSubmit();
    };

    const _onSuccess = () => {
        localStorage.clear();
        clearCreateOrderFields();
        navigate(`/${currentLanguage}/customer/lk/orders`);
    };

    useEffect(() => {
        if (isSuccess) {
            _onSuccess();
        }
    }, [isSuccess]);

    const handleChange = (value: string) => {
        updateField({
            name: TITLE,
            value,
        });
    };
    const _back = () => {
        if (back) {
            back("wishes");
        }
        navigate(`/${currentLanguage}/customer/create-order/wishes`);
    };

    const handleClick = () => {
        if (isLogin) {
            return _onSubmit();
        }
        login();
    };

    const crumbs = [
        {
            name: getItemCreateOrder(SERVICE_CATEGORY_NAME) || entities?.service_task?.value,
            link: `create-order/${categoryPath}`
        },
        {
            name: getItemCreateOrder(SERVICE_TYPE_NAME) || entities?.type_of_service_2?.value,
            link: `create-order/${categoryPath}/${typePath || 'type'}`
        },
        {
            name: getItemCreateOrder(SERVICE_SUB_TYPE_NAME) || serviceSubTypesIdsLength && `${entities?.subtype_of_service_2?.value} (${serviceSubTypesIdsLength})` || entities?.subtype_of_service_2?.value,
            link: `create-order/${categoryPath}/${typePath || 'type'}/${subtypePath || 'subtype'}`
        },
        {
            name: entities?.choosing_a_specialist?.value,
            link: 'create-order/meeting-point'
        },
        {
            name: entities?.date_and_time?.value,
            link: 'create-order/when-to-start'
        },
        {
            name: entities?.periodicity?.value,
            link: 'create-order/repeat'
        },
        {
            name: entities?.additionally?.value,
            link: 'create-order/wishes'
        },
        {
            active: true,
            name: entities?.application_name?.value
        }
    ]

    return (
        <div className="inner-container">
            <div className="new-order__wrapper">
                <Breadcrumbs
                    crumbs={crumbs}
                />
                <div className="new-order__header">
                    <h2 className="title title--f46">
                        {entities?.order_finish_title?.value}
                    </h2>
                    <CancelOrderButton
                        profile={profile}
                        entities={entities}
                    />
                </div>
                <div className="new-order__form form">
                    <Form
                        onSubmit={(e: React.FormEvent<HTMLInputElement>) =>
                            _onSubmit(e, true)
                        }
                        isSuccess={isSuccess}
                        error={error}
                    >
                        {() => (
                            <>
                                <div className="form__row form__row--big-margin">
                                    <fieldset>
                                        <legend>
                                            {
                                                entities
                                                    ?.order_finish_order_name
                                                    ?.value
                                            }
                                        </legend>
                                        <Input
                                            type="text"
                                            placeholder=""
                                            maxLength={50}
                                            value={title}
                                            onChange={handleChange}
                                        />
                                    </fieldset>
                                </div>
                                <div className="new-order__authorization">
                                    {!isLogin && (
                                        <p className="new-order__authorization-heading">
                                            {
                                                entities
                                                    ?.order_finish_order_auth_text
                                                    ?.value
                                            }
                                        </p>
                                    )}
                                    <p className="new-order__authorization-text">
                                        {
                                            entities
                                                ?.order_finish_order_auth_text_placeholder
                                                ?.value
                                        }
                                    </p>
                                </div>

                                <div className="form__row form__controls">
                                    <button
                                        className="btn form__back-link btn--transparent"
                                        type="button"
                                        onClick={_back}
                                    >
                                        {entities?.login_return_button?.value}
                                    </button>
                                    <button
                                        className="btn form__back-link btn--transparent"
                                        type="button"
                                        onClick={handleClick}
                                    >
                                        {isLogin
                                            ? entities?.order_save_as_draft
                                                ?.value
                                            : entities
                                                ?.login_phone_confirmation_enter_button
                                                ?.value}
                                    </button>
                                    <button
                                        className="btn btn--transparent btn--bg-blue"
                                        type="submit"
                                        disabled={isLoading}
                                    >
                                        {isLogin
                                            ? entities?.order_save?.value
                                            : entities?.registration_link_value
                                                ?.value}
                                    </button>
                                </div>
                            </>
                        )}
                    </Form>
                </div>
            </div>
            <ul className="advertising-list create-order">
                <Advertising countCards={5} entities={entities} isHorizontal={true} />
            </ul>
        </div>
    );
};

export default Finishing;
