import React, { useEffect, useState } from "react";
import Modal from "../../../../components/Modal";
import ChooseAddress from "../../../../components/ChooseAddress";
import { setItemStore } from "../../../../untils";
import { locationProps } from "../../../../models/orderModel";
import { TranslationObject } from "../../../../models/translationModal";
import { endpoints } from "../../../../store/user/user.api";
import cn from "classnames";
import {
    getItemCreateOrder,
    getItemEditOrder,
} from "../../../../store/order/selectors";
import { MY_ADDRESS } from "../../../../store/order/constant";

const MyAddressModal: React.FC<{
    entities?: TranslationObject;
    showMyAddressModal: boolean;
    close: () => void;
    save: (location: locationProps) => void;
    isSpecialist?: boolean;
    isEdit?: boolean;
}> = ({
    entities,
    showMyAddressModal,
    close,
    save,
    isSpecialist = false,
    isEdit = false,
}) => {
    const { data: profile } = endpoints.getProfile.useQuery();
    const myCoordinates = isEdit
        ? getItemEditOrder(MY_ADDRESS)
        : getItemCreateOrder(MY_ADDRESS);

    const [location, setLocation] = useState<locationProps | null>(null);

    useEffect(() => {
        const { address, latitude, longitude } = profile || {};

        if (address && latitude && latitude) {
            if (
                !myCoordinates ||
                (address && myCoordinates.address !== address) ||
                (latitude && myCoordinates.latitude !== latitude) ||
                (longitude && myCoordinates.longitude !== longitude)
            ) {
                setLocation({
                    latitude: latitude,
                    longitude: longitude,
                    address: address,
                });
                return;
            }
        }

        if (myCoordinates) {
            setLocation({
                latitude: myCoordinates.latitude,
                longitude: myCoordinates.longitude,
                address: myCoordinates.address,
            });
        }
    }, [profile, myCoordinates]);


    const changeLocation = (obj: any) => {
        setLocation({
            latitude: obj?.latitude || null,
            longitude: obj?.longitude || null,
            address: obj?.address,
        });
        if (!isEdit) {
            setItemStore("my_address", {
                latitude: obj?.latitude,
                longitude: obj?.longitude,
                address: obj?.address,
            });
        }
    };

    return (
        <Modal
            visible={showMyAddressModal}
            key="MyAddressModal"
            modificators={[
                !isSpecialist ? "customer" : "",
                "modal-specialist address-modal",
            ]}
            onClose={close}
            title={entities?.order_select_address_title?.value}
        >
            <div>
                <ChooseAddress
                    inputClasses={["custom-input--suffix form__field"]}
                    changeLocation={changeLocation}
                    withRadius={false}
                    entities={entities}
                    location={location}
                    isSpecialist={isSpecialist}
                />
                <div className="form__row form__controls form__row--end">
                    <button
                        className="btn form__control form__back-link btn--transparent"
                        type="button"
                        onClick={close}
                    >
                        {entities?.common_cancel_button?.value}
                    </button>
                    <button
                        className={cn("btn form__control", {
                            "btn--bg-green": isSpecialist,
                        })}
                        type="button"
                        onClick={() => location && save(location)}
                    >
                        {entities?.common_save_button?.value}
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default MyAddressModal;
