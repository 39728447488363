import { TranslationObject } from "./../../../../models/translationModal";
import { useDispatch, useSelector } from "react-redux";
import { chatMessagesSelector } from "../../../../store/chat/chat.selectors";
import {
    util as chatUtil,
    endpoints as chatEndpoints,
} from "../../../../store/chat/chat.api";
import { MutableRefObject, useEffect, useRef } from "react";
import _ from "lodash";
import { useActions } from "../../../../hooks/actions";
import Echo from "laravel-echo";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import io from "socket.io-client";
import "moment/locale/ru";
import moment from "moment";
import { useGetDirectoriesQuery } from "../../../../store/directories/directories.api";
import { useViewChatMessageMutation } from "../../../../store/chat/chat.api";
import { IMessage } from "../../../../models/chatModels";
import { getCurrentLanguage } from "../../../../store/directories/selectors";

interface UseGetChatMessagesData {
    chatId?: string | number;
    token: string;
    userId?: string | number;
    role?: string;
    chatRef: MutableRefObject<HTMLDivElement | null>;
    entities?: TranslationObject;
}

// TODO: тяжело читаемый хук, нужно рефакторить
export const useGetChatMessages = ({
    chatId,
    userId,
    token,
    role,
    chatRef,
    entities,
}: UseGetChatMessagesData) => {
    const { data: directoriesData } = useGetDirectoriesQuery();
    const [viewChatMessage] = useViewChatMessageMutation();

    const { setChatMessages, addChatMessage, addChatMessages, resetChatState } =
        useActions();

    const currentLanguage = getCurrentLanguage();

    const chatMessages = useSelector(chatMessagesSelector);

    const nextCursor = useRef<string | null>(null);

    const isCursorFetching = useRef<boolean>(false);

    const newCursorMessages = useRef<boolean>(false);

    const chatScrollHeight = useRef<number | null>(null);

    const dispatch = useDispatch();

    const [
        getChatMessages,
        { data: chatMessagesData, isSuccess: isSuccessChatMessages },
    ] = chatEndpoints.getChatMessages.useLazyQuery();

    const scrollToBottomChat = () => {
        if (!chatRef.current) return;

        chatRef.current.scrollTo({
            top: chatRef.current.scrollHeight,
            behavior: "smooth",
        });
    };

    useEffect(() => {
        return () => {
            nextCursor.current = null;
            newCursorMessages.current = false;
            chatScrollHeight.current = null;
            resetChatState();
        };
    }, []);

    useEffect(() => {
        if (chatId) {
            const requestData = {
                chatId, params: {
                    per_page: 15, page: 1,
                },
            };
            getChatMessages(requestData);
        }
    }, [chatId, currentLanguage]);

    useEffect(() => {
        const messages = chatMessagesData?.items;
        if (messages?.length) {
            const requestData = {
                messageId: messages[0]?.id,
                chatId,
            };

            viewChatMessage(requestData)
            const formatChatMessages = _.reduceRight(
                messages,
                (acc: any[], curr: IMessage) => {
                    const dataMessageDate = moment
                        .utc(curr?.created_at)
                        .local()
                        .locale("ru")
                        .format("D MMMM");

                    if (curr.payload) {
                        curr = updateTextVariables(curr, entities, role);
                    }

                    if (acc.find((el) => el.date === dataMessageDate)) {
                        return acc.map((el) => {
                            if (el.date === dataMessageDate) {
                                return {
                                    ...el,
                                    messages: [...el.messages, curr],
                                };
                            }
                            return el;
                        });
                    } else {
                        return [
                            ...acc,
                            { date: dataMessageDate, messages: [curr] },
                        ];
                    }
                },
                []
            );
            if (nextCursor.current && isCursorFetching.current) {
                addChatMessages(formatChatMessages);
                isCursorFetching.current = false;
                newCursorMessages.current = true;
            } else {
                setChatMessages(formatChatMessages);
            }
        }

        nextCursor.current = chatMessagesData?.next_cursor ?? null;
    }, [chatMessagesData, isSuccessChatMessages]);

    useEffect(() => {
        if (
            chatId &&
            userId &&
            token &&
            role &&
            directoriesData?.echo_server?.host
        ) {
            const client = new Echo({
                broadcaster: `socket.io`,
                host: directoriesData?.echo_server?.host,
                transports: [`websocket`],
                client: io,
                auth: {
                    headers: {
                        authorization: `Bearer ${token}`,
                    },
                },
            });
            client
                .private(`chats.${chatId}.${role}.${userId}`)
                .listen(
                    `.chat_message_was_sent`,
                    function (socketMessage: IMessage) {
                        if (socketMessage) {
                            const socketMessageDate = moment
                                .utc(socketMessage?.created_at)
                                .local()
                                .locale("ru")
                                .format("D MMMM");

                            if (socketMessage.payload) {
                                socketMessage = updateTextVariables(
                                    socketMessage,
                                    entities,
                                    role
                                );
                            }

                            if (
                                socketMessage.code ===
                                "specialist_offers_to_make_a_deal" ||
                                socketMessage.code === "order_offer_reject" ||
                                socketMessage.code ===
                                "customer_stopped_search_specialist"
                            ) {
                                dispatch(chatUtil.invalidateTags(["Chat"]));
                            }

                            addChatMessage({
                                date: socketMessageDate,
                                messages: [socketMessage],
                            });

                            setTimeout(() => {
                                scrollToBottomChat();
                            }, 500);
                        }
                    }
                );

            return () => {
                client.leave(`chats.${chatId}.${role}.${userId}`);
            };
        }
    }, [chatId, userId, token, role, directoriesData?.echo_server?.host]);

    useEffect(() => {
        if (
            chatRef.current &&
            chatMessages?.length &&
            !chatScrollHeight.current
        ) {
            chatRef.current.scrollTop = chatRef.current.scrollHeight;
            chatScrollHeight.current = chatRef.current.scrollHeight;
        } else if (
            chatRef.current &&
            chatMessages?.length &&
            chatScrollHeight.current &&
            newCursorMessages.current
        ) {
            chatRef.current.scrollTop =
                chatRef.current.scrollHeight - chatScrollHeight.current;
            chatScrollHeight.current = chatRef.current.scrollHeight;
            newCursorMessages.current = false;
        }
    }, [chatRef, chatMessages, chatScrollHeight, newCursorMessages]);

    useEffect(() => {
        if (chatMessages.length && newCursorMessages.current) {
            scrollToBottomChat();
        }
    }, [chatMessages]);

    const handleClickNextCursor = () => {
        if (nextCursor.current && chatId) {
            const requestData = {
                chatId,
                params: {
                    per_page: 15,
                    cursor: nextCursor.current,
                },
            };

            getChatMessages(requestData);

            isCursorFetching.current = true;
        }
    };

    return {
        chatMessages,
        handleClickNextCursor,
    };
};

function updateTextVariables(message: IMessage, entities?: TranslationObject, role?: string) {
    let text = message.text;
    const { email, phone, phone_customer, price, title, avatar, full_name } =
        message.payload || {};

    if (title) {
        if (message.code == 'specialist_offers_to_make_a_deal') {
            text = `<p class='title'>${entities?.specialist_header?.value}</p>`.replace(
                ":name",
                `<span class='highlight'>${full_name}</span>`
            ) +
                text;
        } else {
            text =
                `<p class='title'>${role == 'specialist' ? entities?.customer_contacts?.value : entities?.specialist_contacts?.value}</p>` +
                text;
        }
    }

    if (price) {
        text = text.replace(
            ":price",
            `<span class='highlight'>${price.toString()}</span>`
        );
    }

    if (email) {
        text = text.replace(email, `<span class='highlight'>${email}</span>`);
    }

    if (avatar) {
        text += `<img class='avatar' src='${avatar.original_url}' alt='avatar'/>`;
    }

    if (full_name && message.code != 'specialist_offers_to_make_a_deal') {
        text += `<p class='full_name'>${full_name}</p>`;
    }

    if (phone_customer) {
        text = text.replace(phone_customer, `<span class='highlight'>${phone_customer}</span>`);
        text += `<p class='phone'>${phone_customer}</p>`;
    } else if (phone) {
        text = text.replace(phone, `<span class='highlight'>${phone}</span>`);
        text += `<p class='phone'>${phone}</p>`;
    }

    return {
        ...message,
        text,
    };
}
