import React, {
    Dispatch,
    useEffect,
    useState,
    useRef,
    ChangeEvent,
} from "react";
import Input from "../Form/Input";
import GoogleMap from "../GoogleMap";
import Icon from "../Icon";
import {
    useGetLocationByAddressMutation,
    endpoints,
} from "../../store/order/order.api";
import { TranslationObject } from "../../models/translationModal";
import { formatQueryString } from "../../untils";
import { LocationData } from "../../models/orderModel";
import cn from "classnames";
import useClickOutside from "../../hooks/useClickOutside";
import { useDebounce } from "../../hooks/debounce";
import { DEFAULT_LATITUDE, DEFAULT_LONGITUDE } from "../../store/order/constant";

interface Props {
    setError?: Dispatch<any>;
    withRadius: boolean;
    entities?: TranslationObject;
    changeLocation: (location: LocationData) => void;
    location?: LocationData | null;
    inputClasses?: string[];
    changeAddress?: (value: string) => void;
    isSpecialist?: boolean;
    isRequired?: boolean;
    errorRequired?: boolean
}

const ChooseAddress: React.FC<Props> = ({
    setError,
    withRadius,
    entities,
    changeLocation,
    location,
    inputClasses = [],
    isSpecialist = false,
    isRequired = false,
    errorRequired = false
}) => {
    const [search, setSearch] = useState<string>(location?.address || "");
    const [getAddress, { data }] = useGetLocationByAddressMutation();
    const [getGeoCodingAutoComplete, { data: addressesData }] =
        endpoints.getGeoCodingAutoComplete.useLazyQuery();
    const [isSelectedAddress, setIsSelectedAddress] = useState<boolean>(true);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const errorValidation = entities?.validation_required?.value?.replace(':attribute', `"${entities?.order_distance?.value} (${entities?.order_distance_km?.value})"`)

    useEffect(() => {
        if (location?.address) {
            setSearch(location?.address);
        }
    }, [location]);

    const button = (
        <button
            className="address-field__button"
            type="button"
            aria-label="Search"
        >
            <Icon type="search" width={20} height={20} />
        </button>
    );

    const [addresses, setAddresses] = useState<
        {
            suggestion: string;
            types: any;
        }[]
    >([]);

    const debounced = useDebounce(search);
    const $collapse = useRef<HTMLDivElement>(null);
    useClickOutside($collapse, () => setIsOpen(false));

    useEffect(() => {
        if (data) {
            changeLocation(data);
            setIsSelectedAddress(true);
        }
    }, [data]);

    useEffect(() => {
        if (debounced?.length > 2 && !isSelectedAddress) {
            getGeoCodingAutoComplete({
                params: formatQueryString({ address: debounced }),
            });
        }
    }, [debounced]);

    const onChange = (value: string) => {
        if (isSelectedAddress) {
            setIsSelectedAddress(false);
        }
        setSearch(value);
    };

    useEffect(() => {
        if (addressesData && addressesData?.length > 0) {
            setAddresses(addressesData || []);
            setIsOpen(true);
        }
    }, [addressesData]);

    const getUserLocation = () => {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                if (position) {
                    const geocoder = new window.google.maps.Geocoder();
                    geocoder
                        ?.geocode({
                            location: {
                                lat: position?.coords.latitude,
                                lng: position?.coords.longitude,
                            },
                        })
                        ?.then((response) => {
                            if (response?.results?.length > 0) {
                                const newLocation = response?.results?.[0]?.geometry.location;

                                changeLocation({
                                    latitude: newLocation.lat(),
                                    longitude: newLocation.lng(),
                                    radius: location?.radius,
                                })
                            }
                        })?.catch(() => {
                            changeLocation({
                                latitude: position?.coords.latitude,
                                longitude: position.coords.longitude,
                                radius: location?.radius,
                            })
                        })
                }
            },
            function (error) {
                if (setError) {
                    setError(error);
                }
            },
            { enableHighAccuracy: true }
        );

    };

    const changeAddressToPosition = (obj: any) => {
        changeLocation({
            ...location,
            ...obj,
        });
        setIsSelectedAddress(true);
    };

    const handleChangeLocation = (obj: any) => {
        changeLocation({
            ...location,
            ...obj,
        });
    };

    const handleClickAddress = (address: string) => {
        getAddress({ address: address });
        setIsSelectedAddress(true);
        setSearch(address);
        setIsOpen(false);
    };

    const handleChangeRadius = (event: ChangeEvent<HTMLInputElement>) => {
        if (location) {
            const target = event.target as HTMLInputElement;
            let value = target.value;

            if (value.startsWith('0')) {
                value = value.replace(/^0+/, '');
            }

            const numericValue = value ? parseInt(value) : undefined;

            if (numericValue === undefined || (numericValue >= 1 && numericValue <= 200)) {
                changeLocation({
                    ...location,
                    radius: numericValue,
                });
            }

            target.value = value;
        }
    };

    return (
        <>
            <div className="form__row form__row--big-margin">
                <Input
                    value={search}
                    onChange={onChange}
                    type="search"
                    placeholder={location?.address || ""}
                    classes={["address-field", ...inputClasses]}
                    button={button}
                    required={isRequired}
                />
            </div>
            {isOpen && (
                <div
                    className={cn("dotted-menu", {
                        "is-active": isOpen,
                    })}
                    ref={$collapse}
                >
                    <ul className="dropdown-menu__list">
                        {addresses.map((address, key) => (
                            <li
                                onClick={() => handleClickAddress(address?.suggestion)}
                                className="dropdown-menu__item"
                                key={key}
                            >{address?.suggestion}</li>
                        ))}
                    </ul>
                </div>
            )
            }
            <GoogleMap
                className="order-map"
                zoom={18}
                position={{
                    lat: location?.latitude || DEFAULT_LATITUDE,
                    lng: location?.longitude || DEFAULT_LONGITUDE,
                }}
                changeLocation={handleChangeLocation}
                center={{
                    lat: location?.latitude || DEFAULT_LATITUDE,
                    lng: location?.longitude || DEFAULT_LONGITUDE,
                }}
                radius={location?.radius}
                changeAddressToPosition={changeAddressToPosition}
            />
            <div className="order-map__link-wrap">
                <div className="order-map__link" onClick={getUserLocation}>
                    <Icon
                        type="location-current-color"
                        colorVariant={isSpecialist ? "greenLight" : "blue"}
                    />
                    <div className={cn({"btn--color-green": isSpecialist})}>{entities?.order_determine_my_location?.value}</div>
                </div>
                <div className="order-map__text">{entities?.order_place_your_address?.value}</div>
            </div>
            {
                withRadius && (
                    <fieldset>
                        <legend>{`${entities?.order_distance?.value} (${entities?.order_distance_km?.value})`}</legend>
                        <div className={errorRequired ? "custom-input custom-input--text-right custom-input--small is-invalid radius" : "custom-input custom-input--text-right custom-input--small"}>
                            <label>
                                <input
                                    onChange={handleChangeRadius}
                                    value={location?.radius}
                                    type="number"
                                    placeholder={entities?.placeholder_max_radius?.value}
                                    required={true}
                                    className={"custom-input"}
                                    min={1}
                                    max={200}
                                    disabled={!search.trim()}
                                    style={{
                                        backgroundColor: !search.trim() ? '#f0f0f0' : 'white',
                                        color: !search.trim() ? '#a0a0a0' : 'black',
                                        cursor: !search.trim() ? 'not-allowed' : 'text'
                                    }}
                                />
                            </label>
                            {errorRequired && <div className="custom-input__error">{errorValidation || ''}</div>}
                        </div>
                    </fieldset>
                )
            }
        </>
    );
};
export default ChooseAddress;
