import React, { useEffect } from "react";
import Form from "../../components/Form/Form";
import { convertDateFormatISO, getItemStore, scrollTop } from "../../untils";
import { TranslationObject } from "../../models/translationModal";
import { useNavigate } from "react-router-dom";
import { getCurrentLanguage } from "../../store/directories/selectors";
import DateStartAndEnd from "../../components/DateStartAndEnd";
import {
    getItemCreateOrder,
    updateItemCreateOrder,
} from "../../store/order/selectors";
import {
    ENDED_AT,
    STARTED_AT,
    SERVICE_CATEGORY_NAME,
    SERVICE_TYPE_NAME,
    SERVICE_SUB_TYPE_IDS,
    SERVICE_SUB_TYPE_NAME,
    SERVICE_TYPE_SLUG,
    SERVICE_SUB_TYPE_SLUG,
    SERVICE_CATEGORY_SLUG, IS_PRIVATE,
} from "../../store/order/constant";
import { createOrderState } from "../../store/order/createOrder.slice";
import { useActions } from "../../hooks/actions";
import Advertising from "../../components/Advertising";
import CancelOrderButton from "./Specialists/CancelOrderButton";
import Breadcrumbs from "../../components/Breadcrumbs";

const StartAt: React.FC<{
    entities?: TranslationObject;
    back: any;
    onSubmit: (
        e: React.FormEvent<HTMLInputElement>,
        nextStep?: string
    ) => Promise<{ data: any } | { error: any } | undefined>;
    onSuccess: (value: string) => void;
    isSuccess: boolean;
    isIndividualOrder: boolean;
    isPrivate?: boolean;
    profile?: ProfilePayload;
}> = ({
    entities,
    profile,
    onSuccess,
    isSuccess,
    onSubmit,
    error,
    isPrivate,
    isIndividualOrder,
}) => {
    const startAt = getItemCreateOrder(STARTED_AT);
    const endAt = getItemCreateOrder(ENDED_AT);
    const currentLanguage = getCurrentLanguage();
    const navigate = useNavigate();
    const { updateCreateOrderField } = useActions();
    const serviceSubTypesIdsLength = getItemCreateOrder(SERVICE_SUB_TYPE_IDS)?.length;

    const categoryPath = getItemCreateOrder(SERVICE_CATEGORY_SLUG);
    const typePath = getItemCreateOrder(SERVICE_TYPE_SLUG);
    const subtypePath = getItemCreateOrder(SERVICE_SUB_TYPE_SLUG);

    const _onSubmit = (e: React.FormEvent<HTMLInputElement>) => {
        onSubmit(e, "repeat");
    };

    const _onSuccess = () => {
        onSuccess("repeat");
    };

    const _back = () => {
        let prevStep =
            isPrivate && !isIndividualOrder
                ? `${categoryPath}/${typePath}/${subtypePath}/list-of-specialists`
                : "meeting-point";

        if (getItemStore("is_specialist_address")) {
            prevStep = "select-specialist-address";
        }

        if (
            !getItemStore("is_specialist_address") &&
            getItemStore("is_my_address")
        ) {
            prevStep = "select-my-address";
        }

        navigate(`/${currentLanguage}/customer/create-order/${prevStep}`);
        scrollTop()
    };

    const updateField = <K extends keyof createOrderState>({
        name,
        value,
    }: {
        name: K;
        value: createOrderState[K];
    }) =>
        updateItemCreateOrder(
            {
                name: name,
                value: value,
            },
            updateCreateOrderField
        );

    useEffect(() => {
        if (isSuccess) {
            _onSuccess();
        }
    }, [isSuccess]);

    const handleChange = (
        value: Date | string,
        name: keyof createOrderState
    ) => {
        let date = "";
        if (value !== "") {
            date = convertDateFormatISO(value);
        }
        updateField({
            name,
            value: date,
        });
    };

    const crumbs = [
        {
            name: getItemCreateOrder(SERVICE_CATEGORY_NAME) || entities?.service_task?.value,
            link: `create-order/${categoryPath}`
        },
        {
            name: getItemCreateOrder(SERVICE_TYPE_NAME) || entities?.type_of_service_2?.value,
            link: `create-order/${categoryPath}/${typePath || 'type'}`
        },
        {
            name: getItemCreateOrder(SERVICE_SUB_TYPE_NAME) || serviceSubTypesIdsLength && `${entities?.subtype_of_service_2?.value} (${serviceSubTypesIdsLength})` || entities?.subtype_of_service_2?.value,
            link: `create-order/${categoryPath}/${typePath || 'type'}/${subtypePath || 'subtype'}`
        },
        {
            name: entities?.choosing_a_specialist?.value,
            link: getItemStore(IS_PRIVATE) ? `create-order/${categoryPath}/${typePath || 'type'}/${subtypePath || 'subtype'}/list-of-specialists` : 'create-order/meeting-point'
        },
        {
            active: true,
            name: entities?.date_and_time?.value
        }
    ]

    return (
        <div className="inner-container">
            <div className="new-order__wrapper">
                <Breadcrumbs crumbs={crumbs} />
                <div className="new-order__header">
                    <h2 className="title title--f46">
                        {entities?.order_start_date_title?.value}
                    </h2>
                    <CancelOrderButton profile={profile} entities={entities} />
                </div>
                <div className="new-order__form form">
                    <Form
                        isSuccess={isSuccess}
                        onSubmit={(e: React.FormEvent<HTMLInputElement>) =>
                            _onSubmit(e)
                        }
                    >
                        {() => (
                            <>
                                <DateStartAndEnd
                                    entities={entities}
                                    handleChange={handleChange}
                                    endAt={endAt}
                                    startAt={startAt}
                                    allowSelectionOldDates={false}
                                />
                                <div className="form__row form__controls">
                                    <CancelOrderButton
                                        profile={profile}
                                        entities={entities}
                                    />
                                    <button
                                        className="btn form__back-link btn--transparent"
                                        type="button"
                                        onClick={_back}
                                    >
                                        {entities?.common_back_button?.value}
                                    </button>
                                    <button
                                        className="btn btn--transparent btn--bg-blue"
                                        type="submit"
                                        disabled={!startAt}
                                    >
                                        {entities?.login_continue_button?.value}
                                    </button>
                                </div>
                            </>
                        )}
                    </Form>
                </div>
            </div>
            <ul className="advertising-list create-order">
                <Advertising countCards={5} entities={entities} isHorizontal={true}/>
            </ul>
        </div>
    );
};

export default StartAt;
