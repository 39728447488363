import React, { ReactNode, useMemo } from "react";
import { getDirectories } from "../../store/directories/selectors";
import { Wrapper } from "@googlemaps/react-wrapper";
import point from "../../img/icons/point.svg";
import Loader from "../Loader";

export const Map: React.FC<{
    center: google.maps.LatLngLiteral;
    children: ReactNode;
    zoom: number;
    radius?: number;
    className: string;
    positions?: Coordinats | null;
    changeAddressToPosition: (address: string) => void;
    changeLocation: ({
        latitude,
        longitude,
        radius,
    }: {
        latitude: number;
        longitude: number;
        radius?: number;
    }) => unknown;
    position?: Coordinats | null;
}> = ({
    center,
    zoom,
    className,
    children,
    changeLocation,
    radius,
    changeAddressToPosition,
    position
}) => {
        const ref = React.useRef(null);
        const [map, setMap] = React.useState<google.maps.Map | null>(null);
        const [isAllowMovePoint, setIsAllowMovePoint] = React.useState<boolean | null>(true);
        const [tempPoint, setTempPoint] = React.useState<Coordinats | null>(null);

        const dragEnd = () => {
            changeLocation({
                radius: radius,
                latitude: map?.center?.lat(),
                longitude: map?.center?.lng()
            });
        };

        useMemo(() => {
            if (position?.lat && position?.lng) {
                const geocoder = new window.google.maps.Geocoder();
                geocoder
                    ?.geocode({
                        location: {
                            lat: position?.lat,
                            lng: position?.lng,
                        },
                    })
                    ?.then((response: any) => {
                        if (response?.results?.length > 0) {
                            let address = response?.results?.[0]?.formatted_address;
                            const plus_code =
                                response?.results?.[0]?.address_components?.find(
                                    (elem: any) =>
                                        elem.types.indexOf("plus_code") !== -1
                                );
                            if (plus_code) {
                                address = address
                                    ?.replace(plus_code?.long_name + ", ", "")
                                    ?.replace(plus_code?.long_name + " ", "");
                            }
                            changeAddressToPosition({
                                latitude: position?.lat,
                                longitude: position?.lng,
                                address: address,
                            });
                        }
                    })?.catch((e) => alert(e))
            }
        }, [position?.lat, position?.lng])

        React.useEffect(() => {
            if (ref.current && !map) {
                const newMap = new window.google.maps.Map(ref.current, {
                    zoom,
                    center,
                    disableDefaultUI: true,
                });
                setMap(newMap);
            }

            if (map as google.maps.Map) {
                map && google.maps.event.clearListeners(map, "dragend")

                map?.setCenter(center);
                isAllowMovePoint && map?.addListener("dragend", dragEnd);
            }
        }, [ref, map, center, radius, isAllowMovePoint]);

        React.useEffect(() => {
            const keyDown = (event) => {
                if (event.key === 'Control' || event.key === 'Meta') {
                    setIsAllowMovePoint(false)
                    position && setTempPoint(position)
                }
            }

            const keyUp = (event) => {
                if (event.key === 'Control' || event.key === 'Meta') {
                    setIsAllowMovePoint(true);

                    tempPoint && changeLocation({
                        radius: radius,
                        latitude: tempPoint?.lat,
                        longitude: tempPoint?.lng
                    });
                }
            }

            window.addEventListener('keydown', keyDown)
            window.addEventListener('keyup', keyUp)

            return () => {
                window.removeEventListener('keydown', keyDown)
                window.removeEventListener('keyup', keyUp)
            }
        }, [])


        return (
            <>
                <div className={className} ref={ref} />
                {React.Children.map(children, (child) => {
                    if (React.isValidElement(child)) {
                        return React.cloneElement(child, { map, isAllowMovePoint });
                    }
                })}
            </>
        );
    };

const Marker = (options: any) => {

    const marker = useMemo(() => {
        if (!options?.map) {
            return null
        }

        const myLatlng = new window.google.maps.LatLng(
            options?.positions?.lat || options?.map?.center?.lat(),
            options?.positions?.lng || options?.map?.center?.lng()
        );

        return new window.google.maps.Marker({
            positions: myLatlng,
            map: options?.map,
            icon: point,
        })

    }, [options?.map, options?.positions, options?.isAllowMovePoint])


    React.useEffect(() => {
        return () => {
            if (marker) {
                marker.setMap(null);
            }
        };
    }, [marker]);


    useMemo(() => {
        if (marker) {
            marker.setOptions(options);
        }

        if (options?.map && marker) {
            options?.map?.addListener("drag", () => {
                const center = new window.google.maps.LatLng(options?.map?.center?.lat(), options?.map?.center?.lng())
                options?.isAllowMovePoint && marker?.setPosition(center);
            });
        }

    }, [options])

    return null;
};

const Circle = (options: any) => {
    const [circle, setCircle] = React.useState<google.maps.Circle>();
    const obj = {
        strokeColor: "#434BEE",
        strokeOpacity: 0.2,
        strokeWeight: 0,
        fillColor: "#434BEE",
        fillOpacity: 0.2,
        map: options?.map,
        center: options?.position,
        radius: +options?.radius * 1000,
    };

    React.useEffect(() => {
        if (!circle) {
            setCircle(new window.google.maps.Circle(obj));
        }

        return () => {
            if (circle) {
                circle.setMap(null);
            }
        };
    }, [options]);

    React.useEffect(() => {
        if (options?.radius === "" || !options?.radius) {
            if (circle) {
                return circle.setMap(null);
            }
        }
        if (circle && options.radius) {
            circle.setOptions(obj);
        }
    }, [circle, options]);

    return null;
};

type Coordinats = google.maps.LatLngLiteral;

const GoogleMap: React.FC<{
    center: Coordinats;
    position?: Coordinats | null;
    zoom: number;
    className: string;
    radius?: number;
    changeAddressToPosition: (value: string) => void;
    changeLocation: ({
        latitude,
        longitude,
        radius,
    }: {
        latitude: number;
        longitude: number;
        radius?: number;
    }) => unknown;
}> = ({
    center,
    zoom,
    className,
    position,
    radius,
    changeAddressToPosition,
    changeLocation,
}) => {
        const key =
            process.env.NODE_ENV !== "production"
                ? "AIzaSyDbBAyAIysP6PdbqEnANpJazjoC8vwh1gk"
                : getDirectories()?.data?.google_maps?.site_api_key;

        return !key ? (
            <Loader />
        ) : (
            <Wrapper apiKey={key}>
                <Map
                    center={center}
                    zoom={zoom}
                    className={className}
                    radius={radius}
                    changeLocation={changeLocation}
                    position={position}
                    changeAddressToPosition={changeAddressToPosition}
                >

                    <Marker
                        key={key}
                        position={position}
                        changeAddressToPosition={changeAddressToPosition}
                    />
                    <Circle position={center} radius={radius} />
                </Map>
            </Wrapper>
        );
    };

export default React.memo(GoogleMap)
